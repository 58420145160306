import Link from 'next/link';
import Container from '@/components/common/Container/Container';
import Section from '@/components/common/Section/Section';
import Box from '@/components/common/Box';
import Heading from '@/components/common/Heading';

const Page404 = () => {
  return (
    <>
      <Box className="grow w-full h-full  bg-[#f0f8ff]">
        <Container>
          <Section className="py-0">
            <Box className="flex flex-col justify-center items-center">
              <Heading
                headingLevel="h1"
                className="mb-[10px] text-[10rem] text-transparent bg-gradient-[90deg] from-[#008eff] to-[#59c2df] bg-clip-text"
              >
                404
              </Heading>
              <p className="custom-p mb-[4rem] text-[1.75rem] font-light text-center">
                Page Not Found
              </p>
              <p className="custom-p max-md:w-[100%] w-[50%] mb-[1.25rem] font-light leading-[1.25] text-center">
                Oops! It seems like the page you are looking for is missing or
                has been moved. We apologize for any inconvenience caused. You
                may have searched for our services page.
              </p>
              <Box className="m-auto">
                <Link
                  href="/services"
                  className=" mx-auto custom-button custom-button-hover  w-[170px]  cursor-pointer"
                >
                  Our Services
                </Link>
              </Box>
            </Box>
          </Section>
        </Container>
      </Box>
    </>
  );
};

export default Page404;
